/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
.mobile-svg {
  display: none; }

@media (max-width: 576px) {
  .desktop-svg {
    display: none; }
  .mobile-svg {
    display: block; } }

.challenge_auth_title h1 {
  margin-bottom: 0px; }

.challenge_authentication_info {
  margin-bottom: 3rem; }

.terms__link {
  margin-top: 4rem; }
