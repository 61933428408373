/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/*** Mixins ***/
/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/**
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 * 
 */
.oops__outer_container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding-top: 40px;
  background: #24A3AB; }

.oops__container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding-top: 97.5px; }

.oops__inner_container {
  background: #24A3AB;
  position: relative;
  width: 75%;
  margin: 200px auto; }

.oops__background {
  background: linear-gradient(180deg, #40BFC8 0%, rgba(64, 191, 200, 0) 100%); }

.oops__text {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 17.5rem;
  line-height: 246px;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  margin-left: -15px; }

.oops__info, .oops__info_subheader {
  padding-top: 11px;
  margin-bottom: calc(1rem * 2);
  background: #24A3AB;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  margin-top: -36px; }

.oops__info_subheader {
  font-size: 1.5rem;
  margin-top: 0; }

.oops__button {
  font-size: 1.66667rem; }

/* For width 576px and larger: */
@media only screen and (max-width: 768px) {
  .oops__text {
    font-size: 10rem;
    margin-left: -10%;
    position: unset; }
  .oops__button {
    white-space: normal;
    margin-left: 15px !important; }
  .oops__container {
    padding-top: 0; }
  .oops__inner_container {
    margin: 0 auto; } }
