/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/* For width smaller than 576px: */
.Footer {
  display: none; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .Footer {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    background-color: #0d2a33;
    opacity: 1;
    z-index: 2;
    position: relative;
    right: 0;
    bottom: 0;
    left: 0; } }

.footer__links {
  display: grid;
  grid-template-columns: 16% 16% 16% 16% 16%;
  grid-gap: 10px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 20px;
  padding-bottom: 5px;
  justify-content: center; }

.footer__linkcontainer {
  text-align: center; }

/*  border-top: 2px solid #64757B;
*/
.footer__link {
  font-style: normal;
  font-weight: normal;
  line-height: 40px;
  font-size: 1rem;
  letter-spacing: 0.05em;
  color: #fafafa;
  text-align: center; }

.footer__link:hover {
  text-decoration: none;
  color: #0A5161;
  cursor: pointer; }

.footer__copyright {
  font-style: normal;
  font-weight: normal;
  line-height: 2.08;
  font-size: 1rem;
  letter-spacing: 0.05em;
  color: #fafafa;
  text-align: center;
  padding-bottom: 40px;
  opacity: 0.5;
  flex: 1 0 auto; }
