/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
html {
  height: 100%;
  height: stretch;
  /* PostCSS will add vendor prefixes as needed */
  box-sizing: border-box;
  font-size: 12px; }

*,
*:before,
*:after {
  box-sizing: inherit; }

/* For width smaller than 576px: */
body {
  background-color: #F7F7F7;
  color: #0d2a33;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.33333rem;
  line-height: 1.91667rem;
  letter-spacing: 0.01em;
  height: inherit;
  position: relative;
  margin: 0;
  overflow-x: hidden;
  padding: 0; }

a:focus,
Button:focus,
textarea:focus,
input[type]:focus {
  outline: 3px solid transparent !important; }

.App {
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: 100vh; }

/*#D8D8D822 rgba(216, 216, 216, .13) */
/* For width smaller than 576px: */
.AppContent {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  z-index: 2; }

#root {
  height: 100%; }
