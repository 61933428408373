/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/*** Mixins ***/
/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/**
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 * 
 */
/* For width smaller than 576px: */
.minicard__link {
  display: inline-block;
  text-align: center;
  padding: 0 7px;
  color: #0A5161;
  border-top: 4px solid transparent; }
  @media only screen and (min-width: 576px) {
    .minicard__link {
      border-top: 0;
      padding-top: 14px; } }
  .minicard__link:hover {
    border-top: 4px solid #FFBE31;
    color: #108F97; }
    .minicard__link:hover .icon_hover {
      fill: #1F8097; }
    @media only screen and (min-width: 576px) {
      .minicard__link:hover {
        border-bottom: 5px solid #FFBE31;
        border-top: 0;
        color: #108F97;
        height: 68px; }
        .minicard__link:hover .icon_hover {
          fill: #1F8097; } }

.minicard__no_hover {
  border-top: 0; }
  .minicard__no_hover:hover {
    border: none; }

.minicard__icon {
  height: 40px; }

.minicard__text {
  display: block;
  width: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 0.83rem;
  text-align: center;
  letter-spacing: 0.01em;
  white-space: nowrap; }

.minicard__selected {
  border-top: 4px solid #FFBE31; }
  @media only screen and (min-width: 576px) {
    .minicard__selected {
      border-bottom: 5px solid #FFBE31;
      border-top: 0;
      height: 68px; } }

.minicard_selected_text {
  color: #108F97; }

span.minicard__text:hover {
  color: #108F97; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .minicard__icon {
    display: inline;
    width: 40px;
    margin-right: 8px;
    margin-top: 14px;
    margin-bottom: 14px; }
  .minicard__text {
    display: inline-block;
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: auto;
    font-size: 1.17rem;
    letter-spacing: 0.01em; } }
