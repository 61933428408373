/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
.topicBadge {
  padding: 4px 8px 4px;
  margin-right: 10px;
  border-radius: 100px;
  border: 1px solid #1BA3AC;
  background: transparent;
  box-sizing: border-box;
  font-family: 'Nunito Sans',sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 1rem;
  text-align: center;
  letter-spacing: 0.01em;
  color: #1BA3AC; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .topicBadge {
    padding: 2px 11px 2px;
    margin-right: 15px;
    font-size: 1.17rem; } }

.storyitem__badges {
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 1.17rem; }

.storyitem__topic_badge {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 0.85rem;
  text-align: center;
  letter-spacing: 0.01em;
  color: #fafafa;
  margin: 7px 3px 3px 3px;
  padding: 4px 7px 4px;
  background: #1BA3AC;
  border-radius: 100px; }

.storyitem__subtopic_badge {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 0.85rem;
  text-align: center;
  letter-spacing: 0.01em;
  color: #fafafa;
  margin: 7px 3px 3px 3px;
  padding: 4px 7px 4px;
  background: #41C67E;
  border-radius: 100px; }
