/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/*** Mixins ***/
/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/**
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 * 
 */
.maintenance__outer_container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding-top: 40px;
  background: radial-gradient(95.57% 280.75% at 16.5% 23.98%, #1BA3AC 0%, #0A5161 100%, #137888 100%, #0A5161 100%); }

.maintenance__container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding-top: 50px; }

.maintenance__background {
  background: none;
  padding: 4rem 0; }

.maintenance__text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #fff; }

.maintenance__info {
  padding-top: 11px;
  margin-bottom: calc(1rem * 2);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 122.76%;
  /* or 29px */
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF; }

.maintenance__info_subheader {
  font-size: 1.5rem;
  margin-top: 0; }

/* For width 768px and larger: */
@media only screen and (max-width: 768px) {
  .maintenance__container {
    padding-top: 30px; }
  .maintenance__inner_container {
    margin: 0 auto; } }
