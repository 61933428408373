/*** Variables ***/

@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700');

@import '_functions.scss';


// Colors
$site-background: #F7F7F7;
$text-primary: #000;
$text-secondary: #1F8097;    /* Tealish black */
$text-tertiary: #0d2a33;     /* Dark gray? */
$text-tertiary-transparent: rgba(35, 61, 69, .494);     /* Dark gray? */
$text-alert: #F44336;        /* Reddish */
$cta-primary: #FF774D;       /* Deep Orange */
$cta-secondary: rgba(153, 153, 153, 0.2);     /* Nearly white */
$nav-state: #FFBE31;

// Brand color
$brand-primary: #0d2a33;     /* Dark teal */
$brand-secondary: #0A5161;   /* Middle teal */
$brand-tertiary: #fafafa;    /* Cool white */
$brand-tertiary-transparent: rgba(250,250,250,.3);    /* Cool white */
$brand-quarternary: #1BA3AC; /* Teal */
$brand-quarternary-transparent: rgba(64, 191, 200, .33) ; /* Teal transparent */
$brand-quinary: #4b6068;    /* Medium gray */
$brand-card-background: #4A4A4A;          /* Dark gray */
$brand-accent: #41C67E;
$logo-teal: #108F97;

$topical-content-covid-19: #663399; /* Purple */

// Sizing
$base-font-size: 12px;

// Function for converting a px based font-size to rem.
@function calculateRem($size) {
	$remSize: $size / $base-font-size;
	//Default font size on html element is 100%, equivalent to 12px;
	@return #{$remSize}rem;
}

// Spacing
$spacing-single: calculateRem($base-font-size);
$spacing-double: calc(#{$spacing-single} * 2);  // 24px
$spacing-triple: calc(#{$spacing-single} * 3);  // 36px
$spacing-quad: calc(#{$spacing-single} * 4);    // 48px

// Indent -- These are indent rules set in the Figma file.
$indent-primary: $spacing-double;
$indent-secondary: $spacing-quad;

// Media queries
$tablet: 576px;
$desktop: 992px;

// Animation
$anime-in: .4s;
$anime-out: .5s;

// A11y Variables
$pre-focus-outline: 3px solid transparent;
$focus-outline: 3px solid darken($brand-quarternary,15%); // This color was changed so that it would pass contrast on the majority of the app.