/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
.home__container {
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  height: 100%;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  background-color: #303030; }

.terms__link {
  font-size: 1.16667rem;
  line-height: 1.66667rem;
  font-style: italic;
  opacity: 0.75;
  margin-left: 15px; }
  .terms__link label {
    margin-left: 0.5rem;
    margin-top: 0.25rem; }

.terms__link a {
  font-weight: 600; }

.intro__background {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #108F97;
  margin-bottom: 0px;
  height: 100%;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-content: center; }
  .intro__background h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    color: #fafafa;
    padding-bottom: 2.5rem; }
  .intro__background h2::before {
    content: "\2022";
    /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #4b6068;
    /* Change the color */
    font-weight: bold;
    /* If you want it to be bold */
    display: inline-block;
    /* Needed to add space between the bullet and the text */
    width: 1em;
    /* Also needed for space (tweak if needed) */
    margin-left: -1em;
    /* Also needed for space (tweak if needed) */ }
  .intro__background Button {
    position: relative; }

@media only screen and (min-width: 576px) {
  .intro__background h2 {
    padding-bottom: 1rem; } }

.intro__header,
.intro__footer {
  text-align: right;
  margin-bottom: 2rem; }
  .intro__header .Header-logo-link,
  .intro__footer .Header-logo-link {
    margin: 0px;
    padding-right: 15px; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .intro__header,
  .intro__footer {
    width: 100%;
    margin-bottom: 3.33333rem;
    padding-right: 75px; } }

.intro__footer {
  margin-top: unset; }
  .intro__footer Button {
    color: #fafafa !important;
    text-transform: capitalize; }

.intro__content,
.homescreen__content {
  flex: 1 0 auto;
  height: 100%; }

.homescreen__content {
  height: 100%; }

.intro__content_stretch,
.homescreen__content_stretch {
  flex: 1 0 auto; }

.intro__content_stretch {
  height: 100%; }

.homescreen__content_stretch {
  align-content: center; }

.homescreen__background {
  width: 100%;
  background: linear-gradient(0deg, rgba(48, 48, 48, 0.8), rgba(48, 48, 48, 0.8)), url("/src/static/hero-bg.jpg");
  background-position: center, -735px 215px;
  background-repeat: no-repeat;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 100%;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-content: center; }
  .homescreen__background .homescreen__tagline_container {
    background: linear-gradient(355.61deg, rgba(49, 110, 130, 0.1) -0.32%, rgba(255, 255, 255, 0.2) 67.79%), #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    margin-top: 40px;
    padding-top: 2.33333rem;
    padding-bottom: 2.75rem;
    margin-bottom: 0; }
    .homescreen__background .homescreen__tagline_container h1.homescreen__tagline {
      font-family: 'Source Sans Pro', sans-serif;
      color: #1BA3AC;
      font-weight: 600;
      font-size: 2rem;
      margin-top: 0;
      margin-bottom: 2rem; }
  .homescreen__background .homescreen__getstarted {
    margin: 0;
    text-transform: uppercase;
    width: 100%; }
  .homescreen__background .homescreen__ioa_tenets {
    display: flex;
    flex-direction: column;
    border: 2px solid #1BA3AC;
    box-sizing: border-box;
    color: #fafafa;
    text-align: center;
    font-weight: 600;
    margin-top: 2.75rem;
    margin-bottom: 0;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding-left: 0.33333rem;
    padding-right: 0.33333rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
    .homescreen__background .homescreen__ioa_tenets ul {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: center;
      list-style-image: url("/src/static/icons/home/bullet_disc.svg");
      list-style-position: inside;
      margin-bottom: 0;
      padding-inline-start: 0px; }
      .homescreen__background .homescreen__ioa_tenets ul li {
        margin-left: 0.33333rem;
        margin-right: 0.33333rem; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .homescreen__background {
    background: linear-gradient(0deg, rgba(48, 48, 48, 0.66), rgba(48, 48, 48, 0.66)), url("/src/static/hero-bg.jpg");
    background-position: center, right top;
    background-repeat: no-repeat; }
    .homescreen__background .homescreen__tagline_container {
      background-color: #fafafa;
      border-radius: 6px;
      margin-top: 0;
      padding-bottom: 2rem;
      margin-bottom: 2rem; }
      .homescreen__background .homescreen__tagline_container h1.homescreen__tagline {
        font-size: 2.16667rem; }
    .homescreen__background .homescreen__getstarted {
      width: 320px; }
    .homescreen__background .homescreen__ioa_tenets {
      flex-direction: row;
      justify-content: center;
      align-content: center;
      margin-top: 0;
      margin-bottom: 2rem;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0; }
      .homescreen__background .homescreen__ioa_tenets li {
        margin-left: 0.83333rem;
        margin-right: 0.83333rem; }
      .homescreen__background .homescreen__ioa_tenets .homescreen__ioa_tenets_teq {
        padding-right: 6px; } }

/* For width 1800px and larger: we don't want it to repeat */
@media only screen and (min-width: 1800px) {
  .homescreen__background {
    background-size: cover, cover;
    background-repeat: no-repeat; } }

.homescreen__phrase {
  display: none; }

.homescreen__animation_container {
  display: none;
  height: 0; }

/* For width 576px and larger, we don't want any padding on mobile */
@media only screen and (min-width: 576px) {
  .homescreen__animation_container {
    display: block;
    height: 250px; }
  .homescreen__animation_container_initial {
    visibility: hidden; }
  .homescreen__phrase {
    display: inline-block;
    position: absolute;
    color: white;
    font-family: 'Roboto', sans-serif; }
  /* on IE11, never show the phrases or animation is it's not working properly */
  _:-ms-fullscreen, :root .homescreen__phrase {
    visibility: hidden; }
  .homescreen__phrase_1 {
    font-size: 1.5rem;
    font-weight: 400;
    opacity: 0.8; }
  .homescreen__phrase_2 {
    font-size: 2.5rem;
    font-weight: 600;
    opacity: 0.5; }
  .homescreen__phrase_3 {
    font-size: 1.66667rem;
    font-weight: 300;
    opacity: 0.8; }
  .homescreen__phrase_4 {
    font-size: 1.5rem;
    font-weight: 500;
    opacity: 0.6; }
  .homescreen__phrase_5 {
    font-size: 1.33333rem;
    font-weight: 300;
    opacity: 0.5; }
  .homescreen__animation_pos_0 {
    left: 98px;
    top: 145px; }
  .homescreen__animation_pos_1 {
    left: 98px;
    top: 184px; }
  .homescreen__animation_pos_2 {
    left: 374px;
    top: 168px; }
  .homescreen__animation_pos_3 {
    left: 190px;
    top: 205px; } }

/* For width smaller than 575px we need extra top and bottom margins  */
@media only screen and (max-width: 575px) {
  .homescreen__card_item:first-child {
    margin-top: 2rem; }
  .homescreen__card_item:last-child {
    margin-bottom: 2rem; } }

/* For width 576px and larger, we don't want any padding on mobile */
@media only screen and (min-width: 576px) {
  .homescreen__card_item:first-child,
  .homescreen__card_item:nth-child(3) {
    padding-right: 0.25rem !important; }
  .homescreen__card_item:nth-child(2),
  .homescreen__card_item:last-child {
    padding-left: 0.25rem !important; } }

.homescreen__card_container {
  background-color: transparent;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }
  .homescreen__card_container .homescreen__card__icon {
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 90px; }
    .homescreen__card_container .homescreen__card__icon img {
      width: 62px;
      height: 62px; }
  .homescreen__card_container h2 {
    font-weight: 600;
    font-size: 1.58333rem;
    color: #fafafa;
    text-align: left;
    margin-bottom: 0.83333rem; }
  .homescreen__card_container p {
    font-weight: 400;
    color: #fafafa;
    margin-bottom: 0; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .homescreen__card_container {
    background-color: #4A4A4A;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 0.5rem;
    border-radius: 6px; }
    .homescreen__card_container .homescreen__card__icon {
      justify-content: center; }
    .homescreen__card_container p {
      font-weight: 300; } }

/* For width 768-790 we have a card that word-wraps so we have to make sure they're all the same height */
@media only screen and (min-width: 768px) and (max-width: 776px) {
  .homescreen__card_container {
    min-height: 159px; } }

.homescreen__principles_container {
  background-color: #1BA3AC;
  color: #fafafa;
  padding-top: 3rem;
  padding-bottom: 3.5rem;
  text-align: center; }
  .homescreen__principles_container.homescreen__faq {
    background-color: #4A4A4A;
    padding-bottom: 2rem; }
    .homescreen__principles_container.homescreen__faq h2 {
      margin-bottom: 0;
      padding-bottom: 0; }
      .homescreen__principles_container.homescreen__faq h2 + p {
        font-size: 1.16667rem;
        margin-bottom: 9px; }
  .homescreen__principles_container h2 {
    color: #fafafa;
    font-size: 1.58333rem;
    font-weight: 600;
    margin-bottom: 1.25rem; }
  .homescreen__principles_container ul {
    text-align: left;
    font-size: 1.16667rem;
    font-weight: 600;
    margin-bottom: 0;
    padding-inline-start: 2rem;
    list-style-image: url("/src/static/icons/home/bullet_small.svg"); }
    .homescreen__principles_container ul li {
      margin-bottom: 1.5rem; }
      .homescreen__principles_container ul li:last-of-type {
        margin-bottom: 0; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .homescreen__principles_container {
    background-color: #4A4A4A;
    padding-top: 2rem;
    padding-bottom: 3rem;
    margin-bottom: 8rem;
    border-radius: 6px; }
    .homescreen__principles_container.homescreen__faq {
      margin-bottom: 0.5rem; }
    .homescreen__principles_container h2 {
      text-align: center; }
    .homescreen__principles_container ul {
      font-size: 1.33333rem;
      font-weight: 300;
      padding-inline-start: 40px;
      list-style-image: url("/src/static/icons/home/bullet.svg"); }
      .homescreen__principles_container ul li {
        padding-left: 2rem; } }

.homescreen__terms_container {
  background-color: #138e96;
  padding: 95px 53px 60px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .homescreen__terms_container Button {
    margin: 16px; }
  .homescreen__terms_container Button.button__primary:disabled,
  .homescreen__terms_container Button.button__primary:disabled:hover {
    opacity: 1;
    background: linear-gradient(0deg, rgba(255, 119, 77, 0.75), rgba(255, 119, 77, 0.75));
    color: rgba(35, 61, 69, 0.25); }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .homescreen__terms_container {
    padding: 25px;
    height: 100%; } }

/* For width 768px and larger: */
@media only screen and (max-height: 768px) and (min-width: 7768px) {
  .homescreen__terms_container {
    justify-content: unset; } }

.introscreen-enter,
.homescreen-enter,
.homescreen-appear {
  opacity: 0.01; }

.introscreen-enter-active,
.homescreen-enter-active,
.homescreen-appear-active {
  opacity: 1;
  transition: opacity 2000ms; }

.introscreen-exit,
.homescreen-exit {
  opacity: 1; }

.introscreen-exit-active,
.homescreen-exit-active {
  opacity: 0.01;
  transition: opacity 1000ms; }

.introscreen-exit-done,
.homescreen-exit-done {
  opacity: 0; }
