/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
.Header {
  background: #0A5161;
  height: 40px;
  opacity: 1;
  z-index: 3;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  flex: none;
  text-align: right; }

/* For width smaller than 576px: */
.Header__back {
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: 4px;
  left: 20px; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .Header__back {
    display: none; } }

.Header-logo-link {
  margin: 2px 18px;
  padding: 5px;
  display: inline-block; }

.Header-logo-img {
  height: 14px; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .Header-logo-img {
    height: 22px; } }

/* A11y styling for Skip to main content link */
a.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999; }

a.skip-main:focus, a.skip-main:active {
  color: #fff;
  background-color: #000;
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow: auto;
  margin: 10px 35%;
  padding: 5px;
  border-radius: 15px;
  border: 4px solid #FF774D;
  text-align: center;
  font-size: 1.2em;
  z-index: 999; }
