/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/*** Mixins ***/
/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/**
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 * 
 */
.storyinput__container {
  padding-bottom: 50px;
  flex: 1 0 auto; }

.storyinput__data_container {
  padding-top: 25px; }
  .storyinput__data_container h2 {
    margin-top: calc(1rem * 2); }

.storyinput__buttons {
  padding-top: 3rem; }

.storyinput__buttons button {
  line-height: normal;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: .5px; }

.storyinput__data_container strong {
  font-weight: 700; }

.authentication__info {
  margin-bottom: 1rem; }

textarea.storyinput__input.form-control::placeholder {
  opacity: 0.5; }

.modal-storyinput.modal-dialog {
  padding: 15px; }
  .modal-storyinput.modal-dialog ul {
    padding-left: 0; }
    .modal-storyinput.modal-dialog ul li {
      list-style: none; }
      .modal-storyinput.modal-dialog ul li h2 {
        color: #1F8097;
        font-weight: 600;
        font-size: 1.58333rem;
        margin-bottom: 0.33333rem; }

.story_input__a_b_test_buttons {
  max-width: 240px;
  width: 100%;
  height: 40px;
  background: #FF774D;
  color: #fff;
  box-shadow: none; }

.modal.show Button.button__primary,
.modal.show Button.button__tertiary {
  margin-right: 5px;
  margin-left: 5px;
  max-width: 10em; }

.storyinput__buttons Button,
div .test_526 {
  width: 100%;
  margin-bottom: 5px;
  margin-top: 0px; }

.test_1766 {
  max-width: none !important; }

.storyinput__input {
  color: #4b6068;
  width: 100%;
  height: 150px !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: unset;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.035);
  border: 2px solid #aaa; }
  .storyinput__input:focus {
    border-color: #009688;
    box-shadow: 0 0 0 1px #009688; }

.storyinput__input:focus,
.storyinput__input::placeholder {
  color: #0d2a33; }

.storyinput__input::placeholder {
  opacity: 0.8; }

.storyinput__otherbehavior {
  margin: 10px 0;
  text-align: center;
  height: 37px;
  min-width: 100%;
  border: 0;
  background: transparent;
  border-bottom: 2px solid #aaa; }
  .storyinput__otherbehavior:focus {
    border-color: #009688;
    box-shadow: 0 0 0 1px #009688; }

.story__input_border {
  margin-top: 0;
  margin-bottom: 0; }

.story__input_letter_text {
  margin: 0;
  margin-top: 10px;
  font-weight: 400; }
  @media only screen and (min-width: 576px) {
    .story__input_letter_text {
      margin: 0; } }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .storyinput__container {
    padding-top: 68px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 100px; }
  .storyinput__data_container {
    background: #fafafa;
    padding: 5rem 0 8rem; }
  .modal-storyinput.modal-dialog {
    padding: 30px; }
    .modal-storyinput.modal-dialog ul {
      padding-left: 40px; }
      .modal-storyinput.modal-dialog ul li {
        padding-left: 5px;
        list-style-image: url("/src/static/icons/home/bullet.svg"); }
        .modal-storyinput.modal-dialog ul li h2 {
          color: #0d2a33;
          font-size: 1.58333rem; } }
