/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
.ombuds__section-header {
  color: #1F8097;
  font-weight: normal;
  font-size: 2rem;
  line-height: normal;
  text-align: center;
  text-wrap: balance;
  margin: 15px auto 5px;
  padding: 0 40px; }

.ombuds__profiles {
  display: block;
  max-width: 1000px;
  padding: 15px 15px 55px; }
  @media only screen and (min-width: 768px) {
    .ombuds__profiles {
      display: flex; } }
  .ombuds__profiles .card {
    background-color: #fafafa;
    border: none;
    padding: 15px; }
    .ombuds__profiles .card img.card-img-top.mx-auto {
      border: 10px solid #1F8097;
      border-radius: 50%;
      margin: 15px;
      max-height: 260px;
      max-width: 260px; }
    .ombuds__profiles .card .card-title {
      color: #1F8097;
      font-size: 2rem;
      font-weight: normal; }
    .ombuds__profiles .card .card-subtitle {
      font-size: 1.5rem;
      font-weight: 300;
      margin-top: 1rem;
      text-wrap: balance; }
    .ombuds__profiles .card .card-text {
      font-size: 1.5rem;
      font-weight: 300;
      text-wrap: balance; }
    .ombuds__profiles .card .btn {
      background: #FF774D;
      border: 3px solid #FF774D;
      border-radius: 2px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
      color: #fff;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.5px;
      margin: 16px;
      margin-left: 0;
      outline: none;
      text-transform: uppercase; }
      .ombuds__profiles .card .btn:not(:disabled):not(.disabled):hover {
        background: #FF774D;
        border-color: #FF774D;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
        color: #fff; }
      .ombuds__profiles .card .btn:not(:disabled):not(.disabled):active {
        background: linear-gradient(0deg, rgba(255, 94, 43, 0.5), rgba(255, 94, 43, 0.5)), #FFFFFF;
        border-color: transparent;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
        outline: none; }
      .ombuds__profiles .card .btn:focus, .ombuds__profiles .card .btn:not(:disabled):focus, .ombuds__profiles .card .btn:active:focus, .ombuds__profiles .card .btn:not(:disabled):not(.disabled):active:focus {
        border: 3px solid transparent;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
        outline: none; }
    .ombuds__profiles .card .card-footer {
      background-color: transparent;
      border: none; }
