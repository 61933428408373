/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/* For width smaller than 576px: */
.checkin__ {
  padding: 0 0 0.5rem;
  margin-bottom: 0; }
  .checkin__ h2 {
    margin-top: 0;
    margin-bottom: 1rem; }
  .checkin__ h5 {
    color: #64757B;
    font-weight: 900;
    padding: 1.25rem 0; }
  .checkin__ .feedback__controls {
    margin-top: 1rem;
    margin-bottom: calc(1rem * 2); }
    .checkin__ .feedback__controls legend {
      margin-bottom: 1rem;
      padding: 0; }
    .checkin__ .feedback__controls:last-of-type {
      margin-bottom: 0; }
  .checkin__ .feedback__controls_inputs {
    margin-top: 1rem; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .checkin__ {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: calc(1rem * 2);
    max-width: 710px; } }

Button.checkin__read_more {
  color: #1F8097;
  margin: 2rem 0 2rem;
  padding-left: 0; }
