.static__doc_viewer {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 0px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .static__doc_viewer {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 68px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px; } }
