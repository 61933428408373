/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
.faq__section_header {
  padding-top: calc(1rem * 2);
  padding-bottom: calc(1rem * 2);
  margin: 0;
  display: inline-block;
  width: 100%;
  font-family: 'Nunito Sans',sans-serif;
  font-size: 1.6rem;
  /*19.2px*/
  font-weight: 600;
  font-style: normal;
  line-height: 1.3;
  /*24px;*/
  letter-spacing: 0.01em;
  color: #4b6068;
  border-bottom: 1px solid rgba(64, 191, 200, 0.33);
  transition: border 300ms ease-in-out; }

.faq__section_header_open {
  border-bottom: none;
  padding-bottom: 1rem; }

.faq__section_header:hover {
  cursor: pointer; }

.faq__section_header span {
  padding-left: 45px;
  padding-top: 3px;
  display: block;
  line-height: 1.6;
  /*24px;*/
  vertical-align: middle; }

.faq__img_closed {
  float: left;
  width: 3rem;
  height: 3rem;
  transform: translate(0, 0px) rotate(180deg);
  transition: transform 300ms ease-in-out; }

.faq__img_open {
  transform: translate(0, -5px) rotate(270deg); }

.faq__content {
  margin: 0 !important;
  border-style: none;
  background-color: transparent;
  border-bottom: 1px solid rgba(64, 191, 200, 0.33);
  border-radius: 0;
  transition: border 300ms ease-in-out;
  padding-bottom: 1rem;
  padding-left: 45px; }
  .faq__content .card-body {
    padding: 0; }

.faq__content a {
  color: #1F8097; }

.faq__content a:hover {
  color: #0A5161; }
