/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
.scheduler__list {
  height: inherit;
  margin: 0 auto;
  max-width: 592px;
  overflow-y: auto;
  padding: 15px;
  /* 1.33rem; */
  width: 100%; }
  .scheduler__list p span {
    color: #0A5161;
    font-weight: bold; }

.scheduler__list_language_vailability {
  padding-top: 12px;
  padding-bottom: 10px;
  text-align: right; }

.scheduler__list_language_vailability span {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  color: #1BA3AC; }

@media only screen and (max-width: 992px) {
  .scheduler__list_language_vailability {
    padding-top: 0;
    text-align: left; } }

@media only screen and (min-width: 576px) {
  .scheduler__list {
    padding-left: 28.8px;
    /* 1.75rem; */
    padding-right: 28.8px;
    /* 1.75rem; */
    padding-bottom: 0;
    padding-top: 0;
    overflow-y: auto; } }

.scheduler__list::after {
  clear: both;
  content: ' ';
  display: block; }

.scheduler__list ul {
  list-style: none;
  margin: 0rem 0 28.8px;
  /* 1.75rem */
  padding-left: 0; }

.scheduler__list--day button {
  background-color: #FF774D;
  border: 0;
  flex: 0 0 auto;
  float: right;
  margin-right: 0;
  padding-left: 3vw;
  padding-right: 3vw; }

@media only screen and (min-width: 576px) {
  .scheduler__list--day button {
    padding-left: 20px;
    padding-right: 20px; } }

.scheduler__list li {
  position: relative; }

.scheduler__list li > div {
  position: relative; }
