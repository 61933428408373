/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
.feedback__controls {
  font-weight: bold;
  margin-bottom: 1.67rem;
  /* 20px */ }

.feedback__controls legend {
  font-style: normal;
  font-weight: 300;
  line-height: 1.43;
  /* 20px */
  font-size: 1.17rem;
  /* 14px */
  letter-spacing: 0.01em;
  color: #0d2a33;
  padding-bottom: .5rem; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .feedback__controls {
    font-weight: bold;
    margin-bottom: 2rem; }
  .feedback__controls legend {
    font-size: 1.33rem; } }

.feedback__controls_inputs {
  display: flex;
  padding-left: 0rem; }

.feedback__controls p {
  flex: 0 0 25%;
  margin: 0;
  position: relative;
  text-align: center; }

.feedback__controls [type=radio] {
  opacity: 0;
  position: absolute;
  left: 4px; }
  .feedback__controls [type=radio]:focus + label {
    outline: 3px solid transparent; }

.feedback__controls label {
  border-radius: 2px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
  color: #fff;
  display: block;
  font: 500 1rem / 2.8 'Roboto',sans-serif;
  letter-spacing: 1%;
  margin: 0 2px;
  padding: 0px 5px;
  transition: box-shadow 150ms linear; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .feedback__controls_inputs {
    display: flex; }
  .feedback__controls label {
    padding: 0px 20px; } }

.feedback__controls label:hover {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 8px rgba(0, 0, 0, 0.12), 0px 8px 8px rgba(0, 0, 0, 0.24); }

.feedback__controls p:first-child label {
  margin-left: 0;
  background: linear-gradient(89.56deg, rgba(13, 42, 51, 0.56) 0.13%, rgba(49, 110, 130, 0.56) 99.24%); }

.feedback__controls p:nth-child(2) label {
  background: linear-gradient(89.56deg, rgba(49, 110, 130, 0.69) 0.13%, rgba(64, 191, 200, 0.69) 99.24%); }

.feedback__controls p:nth-child(3) label {
  background: linear-gradient(89.56deg, rgba(64, 191, 200, 0.69) 0.13%, rgba(64, 191, 200, 0.88) 99.24%); }

.feedback__controls p:last-child label {
  margin-right: 0;
  background: linear-gradient(89.56deg, rgba(64, 191, 200, 0.88) 0.13%, #40bfc8 99.24%); }

.feedback__controls_user_did_answer input:not(:checked) + label {
  background: rgba(79, 79, 79, 0.26);
  color: #818181; }

.feedback__input {
  color: #0d2a33;
  font-size: 1.66667rem;
  width: 100%;
  height: 150px;
  margin-top: 1rem;
  margin-bottom: calc(1rem * 2);
  max-width: unset;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.035);
  border: 2px solid #aaa; }
  .feedback__input:focus {
    border-color: #009688;
    box-shadow: 0 0 0 1px #009688; }

.feedback__input:focus,
.feedback__input::placeholder {
  color: #0d2a33; }

.feedback__input::placeholder {
  opacity: 0.8; }
