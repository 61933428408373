@import '../../../scss/helpers/variables';

.homepage__container {
    width:100%;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    height: 100%;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column; 
    background: #1BA3AC;
  }

  .homepage__screen__getstarted {
    width: inherit;
    text-transform: uppercase;
}

  .terms__link {
    font-size: calculateRem(14px); 
    line-height: calculateRem(20px);
    font-style: italic;
    opacity: 0.75;
    margin-left: 15px;
    label {
      margin-left: calculateRem(6px);
      margin-top: calculateRem(3px);
    }
  }
  
  .terms__link a {
    font-weight: 600;
  }
  
  .homepagescreen__content {
    flex: 1 0 auto;
    height: 100%;
  }
  
  .homepagescreen__content {
    height: 100%;
  }
  
  .homepagescreen__content_stretch {
    flex: 1 0 auto;
  }
  
  
  .homepagescreen__content_stretch {
    align-content: center;
  }


.horizontal_card_container {
    display: flex;
    min-height: 240px;
    background-color: transparent;
    margin-top: 20px;
 }

.flex_container{
  display: flex;
  justify-content: center;
}

 .homepage__screen__background {
    width: 100%;
    background: linear-gradient(0deg, rgba(27, 163, 172, .80),rgba(10, 81, 97, .66));
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100%;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.homepage__screen__tagline {
  color: #F5F5F5;
  margin-top: 80px;
  padding-top: 40px;
  margin-bottom: 30px;
}
h2.homepage__screen__tagline {
  font-family: 'Nunito Sans';
  font-weight: 800;
  margin-bottom: calculateRem(22px);
}
.homepage__screen__ioa_tenets {
  display: flex;
  font-size: 18px;
  flex-direction: column;
  justify-content: center;
  background-color: #0A5161;
  min-height: 60px;
  box-sizing: border-box;
  border-radius: 4px;
  color: #F5F5F5;
  text-align: center;
  font-weight: 600;
  margin-bottom: 0; 
  margin-left: calculateRem(18px);
  margin-right: calculateRem(18px);
  ul {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center; 
    list-style-image: URL('/src/static/icons/home/yellow_bullet_disc.svg');
    list-style-position: inside;
    margin-bottom: 0;
    padding-inline-start: 0px;
    li {
      margin-left: calculateRem(25px);
      color: #FFBE31;
    }
  }
}


.homepage__visitor_count_banner {
    background-color: #4A4A4A;
    width: inherit;
    border-radius: 4px;
    height: 60px;
    padding-top: 15px;
    font-family: "Nunito Sans";
    text-align: center;
    align-items: flex-end;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin-bottom: 0;
}

.homepage__visitor_count_banner_text {
    color: #f5f5f5;
    margin-right: 15px;
    font-weight: 600;
    margin-bottom: 0;
}

.homepage__visitor_count_banner_number {
    color: #FFBE31;
    font-style: italic;
    font-size: 20px;
    font-weight: 900;
    align-self: flex-end;
    margin-bottom: 0;
}

.counter_content_container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@keyframes slideIn {
  from {
      transform: translateY(-10%);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

.homepage__visitor_count_banner.visible {
  animation: slideIn 1.5s ease-out forwards;
}

.homepage__subheader_container {
  text-align: center;
  font-family: "Nunito Sans";
  font-weight: 600;
  font-size: 20px;
}

.white_text {
    color: #FFFF;
}

.homepage__cards_container {
    margin-bottom: 30px;
}

.homepage__screen__talk_read_strategize_learn {
    font-family: 'Nunito Sans','sans-serif';
    font-weight: 600;
    font-size: 18px;
    flex-direction: row;
    margin-bottom: 15px;
    min-height: 20px;
    ul {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: flex-start; 
        list-style-image: URL('/src/static/icons/home/yellow_bullet_disc.svg');
        list-style-position: inside;
        margin-bottom: 0;
        padding-inline-start: 0px;
        li {
          color: #FFBE31;
          margin-left: calculateRem(5px);
          margin-right: calculateRem(5px);
        }
      }  
}


.homepage__screen__principles_container {
    color: #F5F5F5;
    margin-top: calculateRem(15px);
    padding-top: calculateRem(36px);
    padding-bottom: calculateRem(42px);
    text-align: center;
    h2 {
      color: #F5F5F5;
      font-size: calculateRem(20px);
      font-weight: 600; 
      margin-bottom: calculateRem(24px);
    }
    ul {
      text-align: left;
      font-size: calculateRem(18px);
      font-weight: 200;
      list-style-image: URL('/src/static/icons/home/bullet_small.svg');
      margin-bottom: calculateRem(24px);
      padding-inline-start: calculateRem(24px);
      li {
        margin-bottom: 1.5rem;
      }
    }
  }

@media only screen and (min-width: 768px) {
  .homepage__screen__principles_container{
    background-color: #0A5161;
  }

  .homepage__visitor_count_banner{
    box-shadow:  1px 0px 1px 0px rgba(0, 0, 0, 0.13);

  }

    .homepage__screen__getstarted {
      max-width: 370px;
    }
  }

@media only screen and (max-width:767px) {
  h2.homepage__screen__tagline {
    text-align: center;
  }

  .homepage__screen__talk_read_strategize_learn {
    ul {
      display: flex;
    justify-content: center;
    }
    
  }

}

@media only screen and (max-width:576px) {
  .homepage__visitor_count_banner_number{
    font-size: 20px;
  }

  .homepage__visitor_count_banner{
    margin-top: calculateRem(24px)
  }

  .homepage__visitor_count_banner_text{
    margin-right: 10px;
  }

  .counter_container{
    padding-left: calculateRem(16px);
    padding-right: calculateRem(16px);
  }
  .horizontal_card_container {
    margin-top: 30px;
  }

  .homepage__screen__ioa_tenets {
    padding:10px;
  }
  h2.homepage__screen__tagline {
    font-size: calculateRem(22px);
  }

}

@media only screen and (min-width: 576px) {

  .homepage__visitor_count_banner {
    margin-bottom: calculateRem(24px);

  }
    .homepage__screen__principles_container { 
        padding-top: calculateRem(24px);
        padding-bottom: calculateRem(36px);
        margin-bottom: calculateRem(96px);
        border-radius: 6px;
        h2 {
          text-align: center;
          font-size: 24px;
        }
        ul {
          padding-inline-start: 40px;
          list-style-image: URL('/src/static/icons/home/bullet_small.svg');
          li {
            padding-left: calculateRem(24px);
          }
        }
      }

  .homepage__screen__background {
    background: linear-gradient(0deg, rgba(27, 163, 172, .80),rgba(10, 81, 97, .45)), URL('../../../static/Hero_HPv1.png');
    background-position: center, right top; 
    background-repeat: no-repeat;
    .homepage__screen__ioa_tenets {
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin-bottom: 16px;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        .homepage__screen__tagline {
            background-color: #F5F5F5;
            padding-bottom: calculateRem(24px);
            margin-bottom: calculateRem(24px);
            h2.homepage__screen__tagline  {
              font-size: calculateRem(28.8px);
            }
        }
    }
  }

  .homepage__visitor_count_banner_number {
    font-size: 36px;
    font-weight: 900;
  }

  #getstarted {
    width: 320px;
  }

  .homepage__subheader_container,
  .homepage__screen__principles_container,
  .homepage__visitor_count_banner_text {
    font-size: 24px;
    font-weight: 600;
  }



  .homepagescreen__ioa_tenets .homepage__screen__ioa_tenets_teq {
      padding-right: 6px; }
}

