@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
@keyframes ball-scale-ripple {
  0% {
    transform: scale(0.1);
    opacity: 1; }
  70% {
    transform: scale(1);
    opacity: 0.7; }
  100% {
    opacity: 0.0; } }

.ball-scale-ripple > div {
  animation-fill-mode: both;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  border: 2px solid #fff;
  animation: ball-scale-ripple 1s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8); }

/*** Variables ***/
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/*** Mixins ***/
/*** Variables ***/
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/**
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 * 
 */
.custom_results__top.short__background_list {
  margin-bottom: -100px;
  top: -30px; }
  @media only screen and (min-width: 576px) {
    .custom_results__top.short__background_list {
      margin-bottom: 0; } }
  @media only screen and (min-width: 768px) {
    .custom_results__top.short__background_list {
      margin-bottom: -30px;
      top: -70px; } }
  @media only screen and (min-width: 992px) {
    .custom_results__top.short__background_list {
      margin-bottom: -60px;
      top: -100px; } }

.custom_results__top .card__frame {
  background-color: #0A5161;
  padding: 30px 45px 20px; }
  @media only screen and (min-width: 768px) {
    .custom_results__top .card__frame {
      border-radius: 6px; } }
  .custom_results__top .card__frame h1, .custom_results__top .card__frame h4 {
    color: #fafafa; }
  .custom_results__top .card__frame h4 {
    margin-bottom: 1em; }
  .custom_results__top .card__frame a.list-group-item {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8), 0px 15px 30px rgba(0, 0, 0, 0.53); }
    .custom_results__top .card__frame a.list-group-item:hover {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.9), 0px 0px 2px rgba(0, 0, 0, 0.64), 0px 0px 8px rgba(0, 0, 0, 0.64), 0px 8px 8px rgba(0, 0, 0, 0.9); }
  .custom_results__top .card__frame .text-right {
    color: #fafafa;
    font-size: 1.125rem;
    line-height: 1; }
  .custom_results__top .card__frame .text-center img {
    cursor: pointer; }

.custom_results__top .story__summary_card {
  margin-top: 10px; }
  .custom_results__top .story__summary_card .search__list_item_title {
    color: #0d2a33; }

.custom_results__top .iconcard__container {
  border-top: 10px solid transparent;
  padding-right: 15px;
  padding-left: 15px; }
  .custom_results__top .iconcard__container .media,
  .custom_results__top .iconcard__container .iconcard__pills {
    width: 100%;
    padding-bottom: 1.065rem; }

.topic-story h2 {
  font-size: 2.2rem; }

.outcome-icon-loader {
  width: 100%; }

.outcome-icon-loader .iconcard__icon {
  width: 100%; }

.outcome-icon-loader .loader {
  margin-left: 20px; }

.ball-scale-ripple > div {
  transform: scale(2, 2);
  border: 2px solid rgba(110, 127, 133, 0.45);
  margin: auto; }

#CustomResultsAllOutcomes {
  background: linear-gradient(219.54deg, rgba(49, 110, 130, 0.1) -1.76%, rgba(255, 255, 255, 0.2) 61.38%);
  padding-bottom: 100px; }
  #CustomResultsAllOutcomes h1 {
    margin-left: 8.333333%;
    padding-top: 30px; }
    @media only screen and (min-width: 576px) {
      #CustomResultsAllOutcomes h1 {
        padding-top: 10px; } }
    @media only screen and (min-width: 768px) {
      #CustomResultsAllOutcomes h1 {
        padding-bottom: 30px; } }
  #CustomResultsAllOutcomes .card__list.row {
    padding-right: 45px;
    padding-left: 45px; }

@media only screen and (max-width: 576px) {
  .outcome-icon-loader {
    width: 100%; }
  .custom_results__top.card__list {
    margin-top: 0; } }
