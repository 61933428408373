/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
.button__primary__long {
  margin-right: 0px;
  width: 100%;
  max-width: 300px;
  min-height: 45px; }

.email_auth_body_text {
  padding-top: 5px;
  margin-bottom: 14px;
  font-family: Roboto;
  font-style: normal;
  font-size: 24px;
  line-height: 122.76%;
  /* or 29px */
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF; }

.email_auth_headline_text {
  font-family: Roboto;
  font-style: normal;
  font-size: 48px;
  text-align: center;
  color: #fff; }

@media only screen and (max-width: 768px) {
  .email_auth_headline_text {
    font-size: 36px; }
  .email_auth_body_text {
    font-size: 18px; } }

.authentication__info {
  margin-bottom: calc(1rem * 2); }

ul.authentication__info {
  padding-left: 18px; }

/* For width smaller than 576px: */
.authentication__error {
  margin-top: 4px;
  height: 25px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.33;
  /*16px;*/
  font-size: 1rem;
  color: #F44336; }

/* For width smaller than 576px: */
.authentication__confidence {
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 1rem;
  letter-spacing: 0.01em;
  color: rgba(35, 61, 69, 0.494); }

.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=tel] {
  padding-right: .75rem !important; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .authentication__error {
    height: 11px; }
  .authentication__confidence {
    font-size: 1.17rem; } }
