/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
.custom__modal__dialog {
  background-color: #00000000 !important; }

.prodfeedback__modal {
  border-radius: 6px; }

.prodfeedback__header {
  background: #0A5161;
  color: #fafafa;
  margin-right: 0 !important; }

.prodfeedback__header h5 {
  font-weight: 500; }

.prodfeedback__header Button {
  box-shadow: none; }

.prodfeedback__body,
.prodfeedback__footer {
  background: linear-gradient(309.54deg, rgba(49, 110, 130, 0.1) -1.76%, rgba(255, 255, 255, 0.2) 61.38%);
  background-size: 100% 100%; }

.prodfeedback__thanks {
  text-align: left;
  padding-top: 2px; }

@media only screen and (max-width: 576px) {
  .prodfeedback__wrapper .modal-dialog {
    width: 95%; } }
