.scheduler__daynav {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem; }

.scheduler__daynav button {
  margin: 0; }

.scheduler__daynav button:last-child {
  margin-left: 1rem; }
