/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
.faq__hp {
  margin-bottom: 2rem;
  text-align: left; }
  .faq__hp .faq__section_header {
    border-bottom: 1px solid rgba(250, 250, 250, 0.3);
    color: #fafafa;
    display: inline-block;
    font-family: 'Nunito Sans',sans-serif;
    font-size: 1.16667rem;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.01em;
    line-height: 1.3;
    /*24px;*/
    margin: 0;
    padding: .5rem 0 .5rem 12px;
    transition: border 300ms ease-in-out;
    width: 100%; }
    .faq__hp .faq__section_header span {
      display: block;
      line-height: 1.9;
      padding-top: 1px;
      padding-left: 24px; }
  .faq__hp .faq__section_header_open {
    border-bottom: none;
    padding-bottom: 1rem; }
  .faq__hp .faq__section_header:hover {
    cursor: pointer; }
  .faq__hp .faq__img_closed {
    float: left;
    width: 2rem;
    height: 2rem;
    transform: translate(0, 0px) rotate(180deg);
    transition: transform 300ms ease-in-out; }
  .faq__hp .faq__img_open {
    transform: translate(0, 0) rotate(270deg); }
  .faq__hp .faq__content {
    border-style: none;
    background-color: transparent;
    border-bottom: 1px solid rgba(250, 250, 250, 0.3);
    border-radius: 0;
    margin: 0 !important;
    padding-bottom: 1rem;
    padding-left: 35px;
    transition: border 300ms ease-in-out; }
    .faq__hp .faq__content .card-body {
      padding: 0; }
      .faq__hp .faq__content .card-body ul {
        margin-left: 0; }
      .faq__hp .faq__content .card-body li {
        font-style: italic;
        font-weight: 300;
        list-style-image: none;
        list-style-type: disc; }
      .faq__hp .faq__content .card-body li, .faq__hp .faq__content .card-body p {
        font-size: 1.16667rem; }
  .faq__hp .faq__content a {
    color: #1F8097; }
  .faq__hp .faq__content a:hover {
    color: #0A5161; }
  @media (min-width: 576px) {
    .faq__hp .faq__section_header {
      font-size: 1.33333rem;
      padding-left: 13px; }
      .faq__hp .faq__section_header span {
        line-height: 2;
        padding-left: 60px; }
    .faq__hp .faq__img_open {
      transform: translate(0, -5px) rotate(270deg); }
    .faq__hp .faq__img_closed {
      height: 2.75rem;
      width: 2.75rem; }
    .faq__hp .faq__content {
      padding-left: 70px; }
      .faq__hp .faq__content .card-body p,
      .faq__hp .faq__content .card-body li {
        font-size: 1.33333rem; } }
