/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/*** Mixins ***/
/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/**
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 * 
 */
.iconcard__container {
  height: 220px; }
  @media only screen and (min-width: 576px) {
    .iconcard__container {
      min-height: 184px; } }
  @media only screen and (min-width: 768px) {
    .iconcard__container {
      min-height: 224px; } }

.iconcard__container {
  display: flex;
  flex-flow: row; }
  .iconcard__container .media {
    height: 70%;
    padding-left: 15px;
    width: 100%; }
    .iconcard__container .media .iconcard__icon {
      padding-right: 15px; }
      .iconcard__container .media .iconcard__icon img {
        width: 62px; }
    .iconcard__container .media .media-body {
      align-self: center;
      padding-right: 15px; }
  .iconcard__container .iconcard__pills {
    padding: 0 15px;
    width: 100%; }
    @media only screen and (min-width: 576px) {
      .iconcard__container .iconcard__pills {
        padding-bottom: 0.67rem; } }

.iconcard__container.list-group-item {
  background-color: #fafafa;
  border-radius: 6px;
  vertical-align: middle;
  margin-top: 0px;
  margin-bottom: 5px;
  padding-left: 0px;
  padding-right: 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15), 0px 15px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }

.iconcard__title {
  color: #0d2a33; }

.iconcard__text {
  color: #0d2a33;
  font-size: 1.17rem;
  /* 14px */
  font-weight: 300;
  line-height: 1.43;
  /* 20px */
  letter-spacing: 0.01em; }

@media only screen and (min-width: 576px) {
  .iconcard__container .iconcard__pills {
    width: 75%; }
  .iconcard__container.list-group-item {
    margin-top: 11px;
    margin-bottom: 11px;
    min-width: 310px; }
  .iconcard__container.list-group-item:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 8px rgba(0, 0, 0, 0.12), 0px 8px 8px rgba(0, 0, 0, 0.24); }
  .iconcard__container .media {
    height: 75%;
    width: 85%; }
    .iconcard__container .media .iconcard__icon img {
      width: 70px; }
  .iconcard__text {
    line-height: 1.25;
    /* 20px */
    font-size: 1.33rem;
    /* 16px*/ } }

@media only screen and (min-width: 992px) {
  .iconcard__container .media,
  .iconcard__container .iconcard__pills {
    width: 90%; } }

@media only screen and (min-width: 1200px) {
  .iconcard__container .media,
  .iconcard__container .iconcard__pills {
    width: 85%; } }
