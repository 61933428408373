.about__container {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: space-around;
  align-items: center; }

.attributions__text {
  text-align: center; }
