/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/* For width smaller than 576px: */
.content__container {
  background: transparent;
  box-shadow: none;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 6px; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .content__container {
    background: #fafafa;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15), 0px 15px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    margin-bottom: calc(1rem * 4);
    /* 48px; */ } }
