/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
.filter__controls {
  background: #fafafa;
  border-radius: 6px;
  padding-top: 20px;
  padding-bottom: 10px; }

.filter__controls legend {
  font-style: normal;
  font-weight: 300;
  line-height: 1.42rem;
  font-size: 1rem;
  letter-spacing: 0.01em;
  margin-bottom: 10px;
  color: #0d2a33; }

.filter__controls_inputs {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between; }

.filter__controls p {
  flex: 0 1 auto;
  margin: 0;
  position: relative;
  text-align: center; }

.filter__controls [type=checkbox] {
  opacity: 0;
  position: absolute;
  left: 4px; }
  .filter__controls [type=checkbox]:focus + label {
    outline: 3px solid transparent; }

.filter__controls label {
  background: #FFFFFF;
  border: 1px solid #4b6068;
  box-sizing: border-box;
  border-radius: 100px;
  color: #4b6068;
  display: block;
  font-size: 1rem;
  font-family: 'Nunito Sans',sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.01em;
  margin-right: 9px;
  margin-bottom: 10px;
  padding: 2px 11px 2px;
  transition: box-shadow 150ms linear; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .filter__controls {
    padding-left: 0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15), 0px 15px 30px rgba(0, 0, 0, 0.1);
    border-radius: 6px; }
  .filter__controls label {
    margin-right: 17px; }
  .filter__controls label:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15), 0px 15px 30px rgba(0, 0, 0, 0.1); }
  .filter__controls legend,
  .filter__controls label {
    font-size: 1.17rem; } }

.filter__controls input:checked + label {
  font-weight: bold;
  color: #fafafa;
  background-color: #108F97;
  border: 1px solid #1BA3AC;
  padding: 2px 10px 2px; }
