/*** Functions ***/
/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/*** Functions ***/
/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/*** Mixins ***/
/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/**
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 * 
 */
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
.learning__module {
  border-radius: 0;
  background-color: transparent; }
  @media only screen and (min-width: 576px) {
    .learning__module {
      border-top: 37px solid #1BA3AC;
      border-radius: 6px 6px 6px 6px;
      background-color: #fafafa; } }

.learning__module .learning__module_icon {
  display: none; }

.learning__module.topic-covid-19 {
  border-radius: 0;
  background-color: transparent; }
  @media only screen and (min-width: 576px) {
    .learning__module.topic-covid-19 {
      border-top: 37px solid #663399;
      border-left: 0;
      border-right: 0;
      border-radius: 6px 6px 6px 6px;
      background-color: #fafafa; } }

.learning__module.topic-covid-19 .learning__module_icon {
  display: inline-block;
  height: 25px;
  right: 5px;
  position: absolute;
  top: -31px; }

.learning__module_border {
  border-top: 5px solid #1BA3AC;
  margin-top: -1px; }
  @media only screen and (min-width: 576px) {
    .learning__module_border {
      border-top: none; } }

.covid-19.learning__module_border {
  border-top: 37px solid #663399; }
  @media only screen and (min-width: 576px) {
    .covid-19.learning__module_border {
      border-top: none; } }

.learning__module_section, .story__item_text {
  margin: calc(1rem * 3) 0; }
  .learning__module_section h2, .story__item_text h2, .learning__module_section h3, .story__item_text h3 {
    padding-top: 0;
    margin-top: calc(1rem * 2);
    margin-bottom: 1rem; }
    .learning__module_section h2 + p, .story__item_text h2 + p, .learning__module_section h3 + p, .story__item_text h3 + p {
      margin-top: 1rem; }
  .learning__module_section p, .story__item_text p {
    margin-top: 1rem;
    margin-bottom: calc(1rem * 2); }
    .learning__module_section p + p, .story__item_text p + p {
      margin-top: calc(1rem * 2); }
  .learning__module_section ol, .story__item_text ol, .learning__module_section ul, .story__item_text ul {
    padding-left: 1.5rem;
    margin-left: calc(1rem * 2);
    margin-top: calc(1rem * 2);
    margin-bottom: calc(1rem * 2); }
  .learning__module_section ul, .story__item_text ul {
    list-style-type: disc; }
  .learning__module_section li ol, .story__item_text li ol, .learning__module_section li ul, .story__item_text li ul {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: calc(calc(1rem * 4) - 1.5rem); }
  .learning__module_section a, .story__item_text a {
    color: #0A5161;
    text-decoration: underline; }
  .learning__module_section blockquote p, .story__item_text blockquote p {
    font-style: italic;
    border-left: 5px solid #1BA3AC;
    border-radius: 0;
    margin-top: calc(1rem * 2);
    margin-bottom: calc(1rem * 2);
    padding-left: calc(calc(1rem * 2) - 5px);
    font-size: 1.33rem; }

.learning__module_domore_container {
  margin-top: calc(1rem * 4);
  margin-bottom: calc(1rem * 4);
  text-align: center; }
  @media only screen and (min-width: 576px) {
    .learning__module_domore_container {
      text-align: center; } }

.learning__module_domore_container Button {
  margin: 0; }

.learning__module_related_actions_section {
  margin-top: calc(1rem * 4);
  margin-bottom: calc(1rem * 2); }

.learning__module_related_actions_title {
  text-align: center;
  margin-top: 0;
  margin-bottom: calc(1rem * 2);
  color: #0d2a33; }

.learning__module_related_actions_col {
  margin-bottom: 20px; }

.learning__module_related_actions_card {
  box-shadow: none;
  border: 1px solid rgba(100, 117, 123, 0.25); }
  .learning__module_related_actions_card.list-group-item {
    box-shadow: none;
    border: 1px solid rgba(100, 117, 123, 0.25);
    margin-top: 0;
    margin-bottom: 0; }
    .learning__module_related_actions_card.list-group-item:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15), 0px 15px 30px rgba(0, 0, 0, 0.1); }

.learning__module_quoted_text {
  font-style: italic;
  border-left: 5px solid #1BA3AC;
  border-radius: 0;
  margin-top: calc(1rem * 2);
  margin-bottom: calc(1rem * 2);
  padding-left: calc(calc(1rem * 2) - 5px); }

.wp-block-coblocks-highlight__content {
  background-color: unset;
  color: unset; }

.learning__module_tip_text,
.learning__module_risk_text,
.wp-block-coblocks-highlight,
.wp-block-coblocks-alert {
  font-style: normal;
  border-left: none;
  margin-top: calc(1rem * 2);
  margin-bottom: calc(1rem * 2);
  margin-left: 0;
  padding-left: 0;
  text-align: middle; }
  .learning__module_tip_text:before,
  .learning__module_risk_text:before,
  .wp-block-coblocks-highlight:before,
  .wp-block-coblocks-alert:before {
    content: "";
    float: left;
    background-repeat: no-repeat;
    background-size: 80% 80%;
    background-position: 2px 2px;
    width: 23px;
    height: 23px;
    margin-right: 0.25rem; }

.learning__module_tip_text:before,
.wp-block-coblocks-highlight:before {
  background-image: url("/src/static/icons/lm_tip_icon.svg"); }

.learning__module_risk_text:before,
.wp-block-coblocks-alert:before {
  background-image: url("/src/static/icons/lm_risk_icon.svg"); }

@media only screen and (max-width: 576px) {
  .learning__module_related_actions_col {
    padding-bottom: 30px; } }

/*** Functions ***/
/*** Variables ***/
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/*** Mixins ***/
/*** Variables ***/
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/**
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 * 
 */
.story__container {
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 85px;
  padding-left: 0px;
  padding-right: 0px;
  position: relative;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background: linear-gradient(309.54deg, rgba(49, 110, 130, 0.1) -1.76%, rgba(255, 255, 255, 0.2) 61.38%);
  background-size: 100% 100%; }
  @media only screen and (min-width: 576px) {
    .story__container {
      /*border: 1px solid rgba(0, 0, 0, 0.125);*/
      padding-top: 108px;
      padding-bottom: 0px; } }

.story__item_title {
  background-color: transparent;
  margin-top: calc(1rem * 3); }
  .story__item_title h1 {
    margin-bottom: 1rem; }

.story__item_text {
  color: #0d2a33;
  font-weight: 300;
  letter-spacing: 0.01em;
  margin-top: 0; }
  @media only screen and (min-width: 576px) {
    .story__item_text {
      margin-left: 0px;
      margin-right: 0px; } }
  .story__item_text blockquote {
    font-style: italic;
    border-left: 5px solid #1BA3AC;
    border-radius: 0;
    margin-top: calc(1rem * 2);
    margin-bottom: calc(1rem * 2);
    padding-left: calc(calc(1rem * 2) - 5px);
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #0d2a33; }
  .story__item_text p {
    border-left: unset !important;
    font-size: 1.33rem;
    line-height: normal; }
    @media only screen and (min-width: 576px) {
      .story__item_text p {
        line-height: 23px; } }

.story__summary_header {
  width: 100%;
  height: 5px;
  border-radius: 6px 6px 0px 0px;
  background: #1BA3AC; }

.story__summary_card_list {
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 0px;
  margin: 0;
  list-style: none; }
  @media only screen and (min-width: 576px) {
    .story__summary_card_list {
      padding-top: 30px; } }

.story__summary_card {
  margin: 0px 0 10px 0;
  height: 220px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15), 0px 15px 30px rgba(0, 0, 0, 0.1);
  border-radius: 6px !important;
  background-color: #fafafa;
  border-top: 10px solid #1BA3AC;
  border-left: 0;
  border-right: 0; }
  @media only screen and (min-width: 576px) {
    .story__summary_card {
      margin-left: 0px;
      margin-right: 0px;
      min-height: 184px; }
      .story__summary_card:hover {
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 8px rgba(0, 0, 0, 0.12), 0px 8px 8px rgba(0, 0, 0, 0.24);
        cursor: pointer; } }
  @media only screen and (min-width: 768px) {
    .story__summary_card {
      min-height: 224px;
      margin: 0px 0 20px 0; } }

.story__summary_card .story__icon {
  display: none; }

.story__summary_card.topic-covid-19 {
  border-left: 0;
  border-right: 0; }

.story__summary_card.topic-covid-19 .story__icon {
  display: inline-block;
  height: 20px;
  right: 5px;
  position: absolute;
  top: -23px; }

.story__summary_badges {
  margin-top: 1.25rem;
  padding: 0 15px; }
  @media only screen and (min-width: 576px) {
    .story__summary_badges {
      margin-left: 4px;
      margin-top: 0.67rem;
      padding-bottom: 0.67rem; } }

@media only screen and (min-width: 576px) {
  .story__container_hero {
    min-height: 260px;
    padding-top: 40px; } }

@media only screen and (min-width: 576px) {
  .story__item_badges {
    margin-left: 0; } }

@media only screen and (min-width: 576px) {
  .story__item_background {
    border-top: 37px solid #1BA3AC;
    background: #fafafa; } }

@media only screen and (min-width: 768px) {
  .story__item_background {
    background: transparent; } }

.story__item_background .story__icon {
  display: none; }

@media only screen and (min-width: 576px) {
  .story__item_background.topic-covid-19 {
    border-left: 0;
    border-right: 0;
    background: #fafafa; } }

@media only screen and (min-width: 768px) {
  .story__item_background.topic-covid-19 {
    background: transparent; } }

.story__item_background.topic-covid-19 .story__icon {
  display: inline-block;
  height: 25px;
  right: 5px;
  position: absolute;
  top: -31px; }

.story__topic_links {
  text-align: center;
  margin-bottom: calc(1rem * 4); }

.story__topic_links span {
  font-weight: 700;
  margin-right: 20px; }

.story__visit_faq {
  padding: 20px 0;
  text-align: center;
  color: #fff;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  background: linear-gradient(180deg, #1BA3AC 0%, #0A5161 494.63%); }
  @media only screen and (min-width: 576px) {
    .story__visit_faq {
      padding: 60px 120px; } }

.story__visit_faq a {
  color: #fff;
  text-decoration: underline;
  font-weight: 700; }

/*** Functions ***/
/*** Variables ***/
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
.hr__contact {
  left: 97px;
  font-weight: bold;
  margin-left: 20px; }

.hr__contact_email {
  color: #0d2a33;
  font-weight: 900;
  opacity: 1; }

.hr__resources {
  padding-top: 1.5rem;
  padding-bottom: 3rem; }

.hr__select {
  margin-top: 1rem;
  margin-bottom: 2rem; }

.hr__select > div:first-child {
  border: 2px solid #FF774D; }

.hr__select_contact_post {
  border: none; }

.hr__select_contact_post > div:first-child {
  border-color: transparent; }

.hr__contact_info {
  word-break: break-all;
  border: 1px solid rgba(100, 117, 123, 0.25);
  border-top: 5px solid #1BA3AC;
  border-radius: 6px;
  padding-left: 27px;
  padding-right: 27px;
  padding-top: 11px;
  padding-bottom: 16px;
  margin-top: 10px;
  font-weight: 300;
  line-height: 29px;
  font-size: 1.17rem;
  letter-spacing: 0.01em;
  color: #4b6068;
  background-color: #fafafa; }
  .hr__contact_info.active {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15), 0px 15px 30px rgba(0, 0, 0, 0.1); }

/*** Functions ***/
/*** Variables ***/
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
.toughConvos__card {
  width: 100%;
  padding-right: -15px;
  margin-bottom: 1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.toughConvos__link {
  margin-top: 20px; }

.toughConvos__info {
  padding-top: 10px;
  padding-right: 15px;
  padding-left: 15px;
  min-height: 152px; }

@media only screen and (min-width: 576px) {
  .toughConvos__info {
    margin-top: 18px;
    padding: 0; }
  .toughConvos__card {
    margin-bottom: 10px; } }

/*** Functions ***/
/*** Variables ***/
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/*** Mixins ***/
/*** Variables ***/
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/**
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 * 
 */
.interactive__component_wrapper {
  min-height: 47.08333rem;
  max-height: 47.08333rem;
  overflow: auto;
  margin-bottom: 0;
  background-color: #F7F7F7;
  padding: 3rem 2rem 2rem;
  font-size: 1.33333rem; }
  @media only screen and (min-width: 576px) {
    .interactive__component_wrapper {
      background-color: #e9ecef; } }
  .interactive__component_wrapper strong {
    font-weight: bold; }

.intro__text {
  font-size: 1.5rem; }

.interactive__component {
  visibility: hidden; }
  .interactive__component.hide_other_tab {
    display: none; }
  .interactive__component.show {
    display: block;
    visibility: visible; }

.message-container {
  margin: 0.83333rem 0;
  width: 75%; }
  @media only screen and (min-width: 992px) {
    .message-container {
      width: 50%; } }
  .message-container.right {
    float: right; }
  .message-container.left {
    float: right; }
  .message-container .form-text {
    margin-top: 0.5rem;
    padding-left: 0.83333rem; }

.question {
  border-radius: 0.5rem;
  background-color: #fafafa;
  padding: 0.83333rem; }
  .question.prompt {
    border: 1px solid #4b6068; }
  .question.answer {
    border: 1px solid #1BA3AC; }
  .question.output {
    width: 100%;
    max-width: 100%;
    height: 25.83333rem;
    border: 1px solid #1BA3AC; }
  .question.container__only {
    background: transparent;
    border: none; }
    .question.container__only .filter__controls {
      padding: 0; }
      .question.container__only .filter__controls .row div {
        margin: 0;
        padding: 0 0 0 0.83333rem; }
  .question [contenteditable=true]:empty:before {
    content: attr(placeholder);
    display: block;
    /* For Firefox */ }

.interactive__component_filter {
  background: transparent !important;
  box-shadow: none !important; }
  div.interactive__component_filter {
    background: transparent;
    border: none; }

.button-container {
  background-color: #F7F7F7;
  padding: 0 1.66667rem 1.66667rem;
  margin-bottom: 2rem; }
  @media only screen and (min-width: 576px) {
    .button-container {
      background-color: #e9ecef; } }
  .button-container .row {
    padding: 0; }

.button__copy {
  padding: 10px;
  font-weight: 400; }

.button__leadin {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center; }
  @media only screen and (min-width: 992px) {
    .button__leadin {
      text-align: right; } }

/*** Functions ***/
/*** Variables ***/
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
@media only screen and (min-width: 576px) {
  .advice__appt_subtitle {
    margin-bottom: 4rem; } }

.advice__appt_details {
  background-color: #EEEEEE;
  padding: 18px 24px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }
  @media only screen and (min-width: 576px) {
    .advice__appt_details {
      padding: 18px 33px; } }

.advice__appt_instructions {
  margin-top: 2rem;
  margin-bottom: 3rem; }

.advice__appt_note {
  font-size: 1rem;
  letter-spacing: 0.01em;
  line-height: normal; }

.advice__appt_confidence {
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 1rem;
  letter-spacing: 0.01em;
  color: rgba(35, 61, 69, 0.494);
  padding-top: 3rem;
  padding-bottom: 4rem; }

.advice_appt_spacing {
  margin-top: 1rem; }

.search__filter_form {
  position: relative;
  top: 0px;
  bottom: 0%;
  margin-bottom: 0px;
  padding-bottom: 0px; }

.search__filter_checkboxes {
  display: block;
  padding-right: 0px;
  margin-right: 0px;
  margin-left: 0px; }

.search__filter_header {
  position: relative;
  left: 0%;
  right: 0%;
  background: #1BA3AC;
  color: #fafafa;
  margin-bottom: 0px;
  font-family: 'Nunito Sans',sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  font-size: 1.33rem;
  padding-left: 19px;
  padding-bottom: 15px;
  padding-top: 17px; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .search__filter_header {
    top: 0%;
    bottom: 0%;
    margin-top: 20px; } }

/* For width smaller than 576px: */
.search__filter_item {
  position: relative;
  left: 0%;
  right: 0%;
  bottom: 0%;
  top: 0%;
  margin-top: 0px;
  line-height: 24px;
  font-size: 1.33rem;
  vertical-align: baseline;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 10px;
  margin-left: 40px; }

.search__filter_button {
  margin-top: 40px;
  text-align: left;
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  padding-left: 20px; }

.search__list_item_text {
  line-height: normal;
  padding-bottom: 3rem;
  margin-bottom: 0px;
  color: #0d2a33; }

.search__list_item_title_container {
  display: -webkit-box;
  display: -moz-box;
  height: 75%; }

.search__list_item_title {
  margin-top: 0px;
  margin-bottom: 15px;
  min-height: 4rem;
  font-weight: 800;
  line-height: 1.25;
  color: #4b6068;
  display: flex;
  flex-direction: column;
  justify-content: center; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .search__filter_button {
    margin-left: 10px; }
  .search__list_item_text {
    padding-bottom: 2.5rem;
    margin-bottom: 1rem; }
  .search__list_item_title {
    min-height: 117px;
    margin-left: 4px;
    margin-right: 4px;
    line-height: normal; } }

/* For width 768px and larger: */
@media only screen and (min-width: 768px) {
  .search__list_item_title {
    min-height: 156px; } }

.empty__header {
  height: 36px; }
