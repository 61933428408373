/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
.scheduler__card {
  font-family: 'Roboto', sans-serif;
  font-size: 1.33rem;
  margin: 2px 0; }

.scheduler__datecard b,
.scheduler__datecard i {
  font-weight: normal;
  font-style: normal; }

.scheduler__card__input {
  height: auto;
  line-height: 1;
  position: absolute;
  opacity: 0;
  width: auto; }
  .scheduler__card__input:focus + label {
    outline: 3px solid transparent; }

.scheduler__datecard__label {
  display: flex; }

.scheduler__card__label {
  align-items: center;
  background: #0A5161;
  color: #fafafa;
  border: 2px solid #0A5161;
  border-radius: 5px;
  padding: 1.1875rem;
  margin: 0 !important;
  transition: border 250ms ease-in;
  box-sizing: border-box; }

.scheduler__card__input:not(:disabled) + label.scheduler__card__label:hover {
  background: #FFFFFF;
  border: 2px solid #1BA3AC;
  box-sizing: border-box;
  cursor: pointer; }

/*.scheduler__card__input:checked + .scheduler__card__label {
  background: #ffda8a;
}
*/
.scheduler__datecard__day {
  flex: 1 0 65%; }

.scheduler__timecard {
  display: flex;
  overflow: hidden;
  x-align-items: stretch; }

.scheduler__timecard__face {
  flex: 1 0 100%;
  text-align: center;
  transition: flex-basis 250ms ease-in; }

.scheduler__timecard__face label {
  width: 100%; }

.scheduler__datecard__dayname,
.scheduler__timecard__hour {
  color: #fafafa;
  font-size: 1.67rem; }

.scheduler__datecard__fullday,
.scheduler__timecard__duration {
  color: rgba(250, 250, 250, 0.3); }

.scheduler__timecard__hour,
.scheduler__datecard__fullday,
.scheduler__datecard__available b {
  display: block;
  clear: both; }

.scheduler__datecard__available {
  color: #fafafa;
  font-size: 1.17rem;
  text-transform: uppercase; }

.scheduler__card__input:not(:disabled) + .scheduler__card__label:hover .scheduler__timecard__duration,
.scheduler__card__input:not(:disabled) + .scheduler__card__label:hover .scheduler__datecard__dayname,
.scheduler__card__input:not(:disabled) + .scheduler__card__label:hover .scheduler__timecard__hour,
.scheduler__card__input:not(:disabled) + .scheduler__card__label:hover .scheduler__timecard__which_twelve {
  color: #010101; }

.scheduler__card__input:not(:disabled) + .scheduler__card__label:hover .scheduler__datecard__fullday,
.scheduler__card__input:not(:disabled) + .scheduler__card__label:hover .scheduler__datecard__available {
  color: #64757B; }

.scheduler__datecard__unavailable,
.scheduler__datecard__dayname__unavailable {
  color: rgba(250, 250, 250, 0.3); }

.scheduler__confirm {
  flex: 0 0 50%; }

.scheduler__confirm:hover {
  cursor: pointer; }

.scheduler__timecard--selected .scheduler__timecard__face {
  flex-basis: 50%; }
