/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
.doc__viewer {
  width: 100%;
  min-height: 350px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto; }

.doc__viewer button {
  line-height: unset; }

/* For width smaller than 576px: */
.doc__contentPreview {
  min-height: 350px;
  flex: 1 0 auto;
  border: 2px solid #F7F7F7; }

@media only screen and (min-width: 576px) {
  .doc__viewer {
    min-height: 750px; }
  .doc__contentPreview {
    min-height: 750px; } }

/* Firefox needs extra space below the bottom of the doc viewer */
_:-moz-tree-row(hover), .doc__viewer {
  margin-bottom: 50px; }
