/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/* For width smaller than 576px: */
.menu__navbar {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  /* we have 4 icons on mobile */
  grid-gap: 0px;
  background-color: #FFFFFF;
  padding-left: 0;
  padding-right: 0px;
  margin-right: 0px;
  margin-left: 0px; }

.menu__icon {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px; }

.menu__navitem {
  margin-right: 0;
  margin-left: 0px;
  text-align: center;
  cursor: pointer; }

.icon_color {
  fill: #1F8097; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .menu__navbar {
    grid-template-columns: 130px 95px 95px 95px;
    background-color: #fafafa;
    padding-left: 0px;
    height: 68px; }
  .menu__navitem_faq {
    display: inline-block; }
  .menu__navitem_more {
    display: none; }
  .menu__icon {
    width: 40px;
    height: 40px;
    margin-top: 13px;
    margin-bottom: 12px; }
  .menu__navitem {
    margin-right: 0;
    margin-left: 0px;
    width: stretch;
    cursor: pointer; } }
