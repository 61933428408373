.options div {
  margin-top: 18px;
  margin-bottom: 18px;
  border-style: solid;
  border-width: 2px;
  border-color: #50898F;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center; }
