/*
Sitewide brand color variables
- Takes advantage of CSS variables and the :root selector
- Current support chart https://caniuse.com/#search=variables
- Will need to do something else for Microsoft Edge < 16
*/
/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/*** Mixins ***/
/*** Variables ***/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700");
/*** Functions ***/
/* Tealish black */
/* Dark gray? */
/* Dark gray? */
/* Reddish */
/* Deep Orange */
/* Nearly white */
/* Dark teal */
/* Middle teal */
/* Cool white */
/* Cool white */
/* Teal */
/* Teal transparent */
/* Medium gray */
/* Dark gray */
/* Purple */
/**
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 * 
 */
/* For width smaller than 576px: */
.page__navbar {
  background: #FFFFFF;
  box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.5);
  opacity: 1;
  z-index: 3;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  justify-content: center;
  flex-shrink: 0; }
  @media only screen and (min-width: 576px) {
    .page__navbar {
      padding: 5px 0; } }

.page__navbar .menu__navbar {
  margin-left: 0;
  margin-right: 0; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .page__navbar {
    display: block;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
    top: 40px;
    height: 68px;
    padding: 0;
    justify-content: left; } }

.page__container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding-top: 40px; }

.big__background_image {
  background: linear-gradient(105.85deg, rgba(49, 110, 130, 0.2) 19.8%, rgba(196, 196, 196, 0) 77.3%), linear-gradient(180deg, rgba(25, 43, 102, 0.2) 0%, rgba(196, 196, 196, 0) 100%);
  background-size: 100% 100%;
  background-blend-mode: multiply;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 90px;
  border-radius: 0;
  width: 100%;
  /*margin-bottom: -70px;*/
  display: flex;
  flex-direction: column;
  flex: 1 0 auto; }

/*
  min-height: 100%;
  height: auto !important;
  padding-bottom: 50px;
*/
/* For width smaller than 576px: */
.big__background_image:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("/src/static/Background_5.png");
  background-position: -280px 0px;
  background-size: auto 100%;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat !important;
  opacity: 0.6;
  z-index: -1;
  flex: 1 0 auto; }

.big__background_image h1,
.advice__appt_title {
  padding-top: 40px;
  margin-bottom: 18px; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .page__container {
    padding-top: 40px;
    padding-bottom: 0px; }
  .big__background_image {
    padding-bottom: 0px; }
  .big__background_image:after {
    background-position: 0px 0px;
    background-size: 100%; }
  .advice__appt_title {
    margin-top: 18px; } }

/* For width 992px and larger: */
@media only screen and (min-width: 992px) {
  .big__background_image:after {
    width: 100%;
    height: 100%; } }

.story__container Button,
.big__background_image Button,
.short__background Button {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #ffffff;
  margin: 16px;
  margin-left: 0;
  border-style: none;
  outline: none;
  border-radius: 2px; }

Button.button__primary {
  border: 3px solid transparent;
  background: #FF774D;
  border-color: #FF774D;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #ffffff;
  margin: 16px;
  margin-left: 0;
  outline: none;
  border-radius: 2px; }

Button.button__primary:disabled,
Button.button__primary:disabled:hover {
  background: linear-gradient(0deg, rgba(79, 79, 79, 0.26), rgba(79, 79, 79, 0.26));
  border-color: transparent;
  color: rgba(0, 0, 0, 0.38);
  cursor: not-allowed; }

Button.button__primary:not(:disabled):not(.disabled):hover,
Button.story_input__a_b_test_buttons:not(:disabled):not(.disabled):hover {
  background: #FF774D;
  border-color: #FF774D;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  color: #ffffff; }

Button.button__primary:not(:disabled):not(.disabled):active {
  background: linear-gradient(0deg, rgba(255, 94, 43, 0.5), rgba(255, 94, 43, 0.5)), #FFFFFF;
  border-color: transparent;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  outline: none; }

Button.button__primary:focus,
Button.button__primary:not(:disabled):focus,
Button.button__primary:active:focus,
Button.button__primary:not(:disabled):not(.disabled):active:focus {
  border: 3px solid transparent;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  outline: none; }

Button.button__secondary {
  color: #FF774D;
  background: none;
  box-shadow: none;
  border: 1px solid #FF774D;
  border-radius: 2px;
  margin-top: 0; }

Button.button__secondary:not(.is_disabled):not(:disabled):not(.disabled):hover {
  background: #FF774D;
  color: #fafafa;
  border: 1px solid transparent; }

Button.button__secondary:not(:disabled):not(.disabled):active {
  background: linear-gradient(0deg, rgba(255, 119, 77, 0.5), rgba(255, 119, 77, 0.5)), #FFFFFF;
  border-radius: 2px;
  color: #fafafa;
  border: 1px solid transparent; }

Button.button__secondary:focus,
Button.button__secondary:not(:disabled):focus,
Button.button__secondary:active:focus,
Button.button__secondary:not(:disabled):not(.disabled):active:focus {
  background: linear-gradient(0deg, rgba(255, 119, 77, 0.5), rgba(255, 119, 77, 0.5)), #FFFFFF;
  border-radius: 2px;
  color: #fafafa;
  border: 1px solid transparent;
  box-shadow: none;
  outline: none; }

Button.button__secondary:disabled,
Button.button__secondary:disabled:hover {
  color: #4b6068;
  background: none;
  border: 1px solid #4b6068;
  box-sizing: border-box;
  border-radius: 2px; }

Button.button__tertiary {
  background: none;
  box-shadow: none;
  color: #1F8097;
  text-transform: uppercase;
  border: 3px solid transparent; }
  Button.button__tertiary:hover {
    border: 3px solid transparent; }

Button.button__tertiary:not(.is_disabled):not(:disabled):not(.disabled):hover {
  background: linear-gradient(0deg, rgba(153, 153, 153, 0.2), rgba(153, 153, 153, 0.2));
  color: #1F8097; }

Button.button__tertiary:not(:disabled):not(.disabled):active {
  background: linear-gradient(0deg, rgba(153, 153, 153, 0.2), rgba(153, 153, 153, 0.2));
  border-color: transparent;
  box-shadow: none;
  color: rgba(153, 153, 153, 0.2); }

Button.button__tertiary:disabled,
Button.button__tertiary:disabled:hover {
  background: none;
  color: #4b6068;
  cursor: not-allowed; }

Button.button__tertiary:focus,
Button.button__tertiary:not(:disabled):focus,
Button.button__tertiary:active:focus,
Button.button__tertiary:not(:disabled):not(.disabled):active:focus {
  border-color: transparent;
  box-shadow: none;
  outline: none;
  color: rgba(153, 153, 153, 0.2); }

Button.button__link {
  background: none;
  box-shadow: none;
  color: #1f8097;
  margin-top: 0; }

Button.button__link:not(.is_disabled):not(:disabled):not(.disabled):hover {
  text-decoration: underline;
  background: none;
  color: #1f8097; }

Button.button__link:not(:disabled):not(.disabled):active,
Button.button__link:disabled,
Button.button__link:disabled:hover,
Button.button__link:focus,
Button.button__link:not(:disabled):focus,
Button.button__link:active:focus,
Button.button__link:not(:disabled):not(.disabled):active:focus {
  background: none; }

/* For width smaller than 576px: */
.short__background_hero, .medium__background_hero {
  min-height: 156px;
  background: linear-gradient(105.74deg, rgba(49, 110, 130, 0.2) 19.79%, rgba(196, 196, 196, 0) 77.3%), linear-gradient(180deg, rgba(25, 43, 102, 0.2) 0%, rgba(196, 196, 196, 0) 100%), url("/src/static/Background_5.png") 100px -30px;
  background-size: 100%, 100%;
  background-blend-mode: multiply;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: 0px;
  padding-top: 43px; }

.covid__background_hero {
  min-height: 156px;
  background: linear-gradient(105.74deg, rgba(49, 110, 130, 0.2) 19.79%, rgba(196, 196, 196, 0) 77.3%), linear-gradient(180deg, rgba(25, 43, 102, 0.2) 0%, rgba(196, 196, 196, 0) 100%), url("/src/static/covid_bg_final.png") -113px 0;
  background-size: 134vh, 100%;
  background-blend-mode: multiply;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: 0px;
  padding-top: 43px; }

/* For width smaller than 576px: */
.hero__back_wrapper {
  display: none; }

/* For width smaller than 576px: */
.short__background_list {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  opacity: 1;
  margin-bottom: 0px; }

.short__background_list_page {
  position: relative;
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0px;
  opacity: 1;
  margin-bottom: 0px; }

@media only screen and (max-width: 411px) {
  .covid__background_hero {
    background-size: 290vh, 100%; } }

@media only screen and (max-width: 768px) {
  /* For width smaller than 768px: */
  .card__list {
    margin-top: 30px;
    margin-left: 0px;
    margin-right: 0px; }
  /* For width smaller than 768px: */
  .card__list_col {
    padding-left: 15;
    padding-right: 15px;
    padding-bottom: 10px;
    margin: inherit 20px; } }

/* For width smaller than 576px: */
.short__background_content {
  padding-top: 0px;
  opacity: 1;
  margin-bottom: 0px; }

/* For width smaller than 576px: */
.short__background_content_resources {
  padding-bottom: 0px;
  margin-bottom: 0px; }

.content_loading_filler {
  min-height: 100vh; }

@media only screen and (min-width: 576px) {
  .hero__back_wrapper {
    display: inline-block;
    position: absolute;
    left: 0px;
    z-index: 2; }
  .hero__back_img {
    display: inline;
    line-height: 1.22;
    /* 44px */
    cursor: pointer; }
  .hero__title {
    margin-left: 8.33333%; }
  .hero__subtitle {
    margin-left: 8.33333%; }
  .short__background_content_resources {
    padding-top: 20px; } }

.short__background_content h3 {
  padding-top: 18px; }

/* For width smaller than 576px: */
.short__background {
  background: linear-gradient(309.54deg, rgba(49, 110, 130, 0.1) -1.76%, rgba(255, 255, 255, 0.2) 61.38%);
  background-size: 100% 100%;
  width: 100%;
  opacity: 1;
  padding-top: 0px;
  padding-bottom: 85px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  .short__background {
    padding-top: 68px;
    padding-bottom: 0px; }
  .short__background_hero, .medium__background_hero {
    min-height: 260px;
    background: linear-gradient(105.74deg, rgba(49, 110, 130, 0.2) 19.79%, rgba(196, 196, 196, 0) 77.3%), linear-gradient(180deg, rgba(25, 43, 102, 0.2) 0%, rgba(196, 196, 196, 0) 100%), url("/src/static/Background_5.png") no-repeat top -25px right;
    background-size: auto, auto, auto 400px;
    padding-top: 40px; }
  .covid__background_hero {
    min-height: 260px;
    background: linear-gradient(105.74deg, #e0d7eb 51%, rgba(140, 146, 219, 0) 77.3%), linear-gradient(180deg, rgba(224, 215, 235, 0.2) 0%, rgba(140, 146, 219, 0) 100%), url("/src//static/covid_bg_final.png") no-repeat top -25px right;
    background-size: auto, auto, auto;
    padding-top: 40px; }
  .short__background_list {
    height: 100%;
    position: relative;
    top: -70px;
    padding-bottom: 0px; }
  .short__background_list_page {
    height: 100%;
    position: relative;
    top: -70px;
    padding-bottom: 0px; }
  .short__background_content {
    height: 100%;
    padding-bottom: 0px;
    position: relative;
    top: -70px;
    bottom: 0px;
    margin-bottom: -70px;
    /* to offset the relative top position */ } }

.hero__subtitle {
  padding-left: 0;
  padding-bottom: 20px; }

@media only screen and (min-width: 576px) {
  .medium__background_hero .hero__subtitle {
    padding: 20px 0;
    margin-left: 0; } }

/*---- GLOBAL STYLES ------- */
/* reactstrap Input */
.form-control,
Input[type=email] {
  max-width: 591px;
  height: 48px;
  background-color: #ffffff;
  border-radius: 0;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  border: 1px solid rgba(13, 42, 51, 0.12);
  box-sizing: border-box; }

/* reactstrap Input */
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
Input[type=email].is-valid {
  outline: 0 none;
  box-shadow: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #1F8097;
  background-image: none; }

/* reactstrap Input */
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
Input[type=email].is-invalid,
Input[type=challengetext].is-invalid {
  outline: 0 none;
  box-shadow: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #C70808;
  background-image: none; }

button {
  color: #fff;
  background-color: #f25929;
  border-width: 0px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  font: 500 14px / 2.8 'Roboto',sans-serif;
  letter-spacing: 0.5px;
  margin: auto;
  padding: 0 20px; }

a {
  color: #0A5161;
  text-decoration: none; }

a:hover {
  color: #0A5161; }

b {
  font-weight: 700; }

input, textarea {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-size: 1.33rem;
  /*16px*/
  line-height: 1.5;
  /*24px*/ }

h1 {
  font-family: 'Nunito Sans',sans-serif;
  font-size: 2rem;
  /*24px*/
  color: #0d2a33;
  line-height: 1.21;
  /*29px;*/
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0.01em; }

h2 {
  font-family: 'Nunito Sans',sans-serif;
  color: #0d2a33;
  font-size: 1.6rem;
  /*19.2px*/
  font-style: normal;
  font-weight: 900;
  line-height: 1.25;
  /*24px;*/
  letter-spacing: 0.01em; }

h3 {
  font-family: 'Nunito Sans',sans-serif;
  color: #0A5161;
  font-style: normal;
  font-weight: normal;
  font-size: 1.33rem;
  /*16px*/
  line-height: 1.25;
  /*20px;*/
  letter-spacing: 0.15em;
  text-transform: uppercase; }

h4 {
  font-family: 'Nunito Sans',sans-serif;
  color: #0d2a33;
  font-size: 1.33rem;
  /*16px*/
  line-height: 1.25;
  /*20px;*/
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.01em; }

.hot__text {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.17rem;
  /* 14px */
  line-height: 1.42;
  /* 20px */
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-bottom: 33px;
  color: #108F97; }

.text__emphasis {
  font-weight: 700; }

/* For width 576px and larger: */
@media only screen and (min-width: 576px) {
  h1 {
    font-size: 3rem;
    /* 36px */
    line-height: 1.22;
    /* 44px */
    margin-top: 6px;
    margin-bottom: 6px; }
  h2 {
    font-size: 2.4rem;
    /* 28.8px */
    line-height: 1.35;
    /* 39px */ }
  h3 {
    font-size: 1.5rem;
    /* 18px */
    line-height: 1.39;
    /* 25px */ }
  h4 {
    font-size: 1.5rem;
    /* 18px */
    line-height: 1.22;
    /* 22px */ } }

.headless-modal {
  width: 100% !important;
  padding: 10px;
  max-width: 800px; }

.headless-modal .modal-body {
  padding: 1rem 1rem 0 1rem; }

.headless-modal .modal-content {
  border: none;
  margin: 0; }

.headless-modal .btn {
  white-space: normal; }

.headless-modal h1 {
  font-family: Source Sans Pro,sans-serif;
  color: #1BA3AC;
  font-weight: normal;
  font-size: 2.25rem; }

.headless-modal strong {
  font-size: 1.66667rem; }
