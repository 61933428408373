/*
Also see SchedulerCard.css for more styles that affect
this component.
*/
.scheduler__confirm,
.scheduler__confirm:hover,
.scheduler__confirm:active,
.scheduler__confirm:focus {
  background: transparent;
  border-width: 0;
  /* Forcing this style to apply with !important until
.short__background Button selector gets fixed.
See: https://github.com/tEQuitable/teq-app-dev/issues/53
*/
  box-shadow: none !important;
  margin: 0 !important;
  padding: 1.9rem; }

.scheduler__confirm:disabled {
  filter: saturate(0); }

.scheduler__confirm svg {
  display: block;
  margin: 0 auto; }
